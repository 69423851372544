.App-header {
  padding: 16px;
  text-align: center;
}

.App-main {
  padding: 0 16px 60px;
  max-width: 1660px;
  margin: 60px auto 0;
}

.App-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.login-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  width: 200px;
}

button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
}