.dashboardTable-wrapper {
  margin-top: 60px;
  border-radius: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.dashboardTable-header {
  cursor: pointer !important;
  min-width: 180px !important;
}

.dashboardTable-cell-name {
  font-weight: 700;
}

.dashboardTable-status {
  padding: 2px 4px;
  border: 1px solid transparent;
  text-align: center;
  font-size: 14px;
  border-radius: 50px;
  background: #e9cbaf;
  text-transform: capitalize;
}

.green-row {
  background: #afe9d1;
}

.custom-input {
  width: 400px; /* Defina a largura desejada aqui */
  height: 30px; /* Defina a altura desejada aqui */
  border: 1.5px solid #ccc;
  border-radius: 8px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  margin: 0 0 10px;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}

.custom-input:focus {
  outline: none;
}

.filters{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:center;  
}

.dashboardTable-buttonWhatsapp{
  background-color: #25D366;
  border-radius: 50px;
  border: none;
  color: white !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 2px;
  cursor: pointer;
  transition-duration: 0.4s;  
}