.dashboardGrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100% !important;
  background-color: #eee;
  border-radius: 15px;
  padding: 21px;
}

.dashboardGrid-boxes {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.dashboardGrid-boxes-item {
  text-align: center;
  height: 100%;
  margin: 0 !important;
  justify-content: center;
  width: 200px !important;
}

.dashboardGrid-boxes-item .value {
  font-size: 32px;
}

.dashboardGrid-boxes-item .label {
  margin-top: 6px;
  font-weight: 400;
}

.dashboardGrid-boxes-item .purple .value {
  color: #8f8cda;
}

.dashboardGrid-boxes-item .green .value {
  color: #8fcfa7;
}

.dashboardGrid-chart {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
}

.dashboardGrid-select {
  width: 200px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.dashboardGrid-select:hover {
  background-color: #f0f0f0;
}

.dashboardGrid-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.plano-select {
  width: 150px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.selecoesNovosUsers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #eee;

}

.btnAlterarGrafico {
  width: 200px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: #ccc;
  color: #fff;
  z-index: 999;
}


.botoesGraficos {
  display: flex;
  flex-direction: row;
  justify-content: safe center;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #eee;
  margin-bottom: 20px;
  width: 100%;
}
.dashboardTable-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px !important;
  border-radius: 15px;
  padding: 21px;
  text-align: center;

}
.dashboardTable-quantidades {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #eee;
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px !important;
  border-radius: 15px;
  padding: 21px;
  text-align: center;
}